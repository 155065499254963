import classNames from 'classnames';
import { FC, PropsWithChildren } from 'react';
import { Mascot, MascotProps } from './mascot';

type MascotWithNameProps = PropsWithChildren<
  MascotProps & {
    className?: string;
    containerClassName?: string;
    mascotClassName?: string;
    suppressLineClamp?: boolean;
    align?: 'start' | 'center' | 'end';
  }
>;

const variants = {
  xlarge: {
    spacingClassNames: 'space-x-6 md:space-x-8',
  },
  large: {
    spacingClassNames: 'space-x-4 md:space-x-6',
  },
  medium: {
    spacingClassNames: 'space-x-3 md:space-x-4',
  },
  small: {
    spacingClassNames: 'space-x-2 md:space-x-3',
  },
  xSmall: {
    spacingClassNames: 'space-x-2 md:space-x-3',
  },
};

export const MascotWithName: FC<MascotWithNameProps> = ({
  children,
  className,
  containerClassName,
  mascotClassName,
  suppressLineClamp,
  variant: variantName = 'medium',
  align = 'center',
  ...mascotProps
}) => {
  const variant = variants[variantName as keyof typeof variants];

  return (
    <div
      className={classNames('flex', variant?.spacingClassNames, containerClassName, {
        'items-center': align === 'center',
        'items-start': align === 'start',
        'items-end': align === 'end',
      })}
    >
      <Mascot
        className={classNames('flex-shrink-0 print:hidden', mascotClassName)}
        {...mascotProps}
        variant={variantName}
      />
      <div
        className={classNames(
          'flex-1',
          {
            'line-clamp-2': !suppressLineClamp,
          },
          className
        )}
      >
        {children}
      </div>
    </div>
  );
};
